import React from "react";
import HomeCarousel from './HomeCarousel';
import HomeBody from './HomeBody';

import FadeIn from 'react-fade-in';


function Home() {
    return (
     <div className='bg-color-1'>
         <FadeIn>
            <HomeCarousel />
            <HomeBody />
         </FadeIn>
     </div>
    )
}

export default Home;