import React from 'react'

import pic1 from '../images/Runner/RunnersOfNYC.jpeg'
import pic2 from '../images/Runner/Lets_Get_Uncomfortable.jpg'
import pic3 from '../images/Runner/RonRunsNYC.jpeg'
import pic4 from '../images/Runner/BeyondRunning.jpeg'
import pic5 from '../images/Runner/Day1Radio.png'
import pic6 from '../images/Runner/OutsidePodcast.png'

const RunnerBody = () => {

    const data = [
        {
            url: 'https://podcasts.apple.com/us/podcast/runners-of-nyc/id1438435120?i=1000474307328',
            image: pic1,
            title: 'Runners of NYC',
            subTitle: 'Episode 41 – Coffey, Define New York Run Clu‪b‬',
            bodyText: 'Our guest for this episode is Coffey. He is a father, husband, filmmaker, Nike running pacer and the founder of Define New York Run Club. This conversation was on our schedule before the Arbery shooting but we take the first 30 minutes of our talk to address the story, its impact on him and how he relates to the likes of Ahmaud Arbery, Michael Brown and Trayvon Martin. We go back to his roots in North Carolina, how he got his start in fashion and filmmaking, why he got hooked on group running and then ultimately starting his own group.',
        },
        {
            url: 'https://podcasts.apple.com/us/podcast/lets-get-uncomfortable/id1534251036',
            image: pic2,
            title: "Let's_Get_Uncomfortable",
            subTitle: 'Episode 5: Coffey the father, actor, filmmaker leading the running movement for social chang‪e‬',
            bodyText: "Last year Coffey was promoting his film 'About the People' with an all black male cast discussing how to build a stronger black community. In 2020, he is leading a movement through running that is bringing fiction to life. In a year unlike any other, Coffey took to the streets to use his rage over the murders of George Floyd and Breonna Taylor to protest against police brutality and systemic racism. He shared his plans for the movement after the election, he is ready for a 10 year plan.",
        },
        {
            url: 'https://podcasts.apple.com/us/podcast/runchats-with-ronrunsnyc/id1483870610?i=1000486234359',
            image: pic3,
            title: 'RunChats with @RonRunsNYC',
            subTitle: 'RunningToProtest: Coffey Finds His Purpose | RunChats Ep.16 ',
            bodyText: "I'm PROUD to sit down this week for a deep dive discussion with Coffey, aka @thatcoffeyboy. Coffey is a husband, father, actor, filmmaker, Nike pacer/coach, and the founder of Define New York Run Club. Coffey is the epitome of cool and is well-known in the NYC running scene.",
        },
        {
            url: 'https://podcasts.apple.com/us/podcast/beyond-running/id1468033358?i=1000493730873',
            image: pic4,
            title: 'Beyond Running',
            subTitle: 'Episode 12: Running to Make Your Voice Heard with Coffey',
            bodyText: "Coffey is a runner, father, filmmaker, and activist who started organizing groups of people to run with him in response to recent and historic violence against black people. Coffey realized that his power was his ability to organize and empower people to take action, and his running protests quickly gained momentum and appeal as a way for people of all colors and backgrounds to get together and speak out against injustice.",
        },
        {
            url: 'https://podcasts.apple.com/us/podcast/day-1-radio/id1024614112?i=1000447538973',
            image: pic5,
            title: 'Day 1 Radio',
            subTitle: 'About The People w/Coffey & Nashawn Kearse',
            bodyText: "This week's episode of Day 1 Radio features two men behind a short film that is bound to strike up plenty of conversations once it's shown to the masses. The film's writer and producer Coffey and actor Nashawn Kearse stopped by to talk about About the People, a short film that can best be described as a fly-on-the-wall view of the conversation that Black people in America have after each police shooting or any other injustice African-Americans almost exclusively experience. Coffey and Kearse share what it's been like touring the festival circuit, creating the film and what they hope viewers walk away with after watching.",
        },
        {
            url: 'https://podcasts.apple.com/us/podcast/running-while-black-in-new-york/id1090500561?i=1000477467233',
            image: pic6,
            title: 'Outside Podcast',
            subTitle: 'Running While Black in New York',
            bodyText: "There’s been a running boom in the age of coronavirus, with veteran runners and newbies alike lacing up their shoes to get outside. But the experience has not been the same for everyone. Coffey, a well-known figure in New York City’s vibrant running scene as well as a multitalented creative artist, has continued to get his miles in during the pandemic. And like other runners whose skin is black or brown, he has faced the same risks of harassment and violence that were present before the virus arrived—along with new dangers. Coffey also has a deeply considered response to the murders of Ahmaud Arbery, Breonna Taylor, and George Floyd: last week, he released the short film About the People, which examines social injustice and racial inequality in America through a powerful conversation between men who are pillars in the black community. In this episode, Coffey shares his story of falling in love with running in NYC, his perspective on the pain and upheaval of recent weeks, and his bold idea for harnessing the positive energy of runners to make a difference.",
        },

    ]
    return (
        <div>
            {data.map((item, index) => (
                <div>
                    <div className='container d-flex flex-column rounded my-3 p-4 bg-color-2 runnerCardHoverEffect-Outer' style={{ width: ''}}>
                        <a href={item.url} style={{ textDecoration: 'none' }}>
                            <div className="d-flex align-items-center p-3 flex-column flex-md-row rounded runnerCardHoverEffect-Outer" >
                                    <div className=''>
                                        <img style={{width: '200px', borderRadius: '5px'}} className="card-img-top" src={item.image} alt="Podcasts about Coffey"/>
                                    </div>
                                    <div className="card-body text-center text-md-left bg-color-3 rounded ml-3">
                                        <h5 className="card-title" style={{ color: 'black' }}>{item.title}</h5>
                                        <p className="card-text" style={{ color: 'black' }}>{item.subTitle}</p>
                                        <p className="card-text text-muted">{item.bodyText}</p>
                                    </div>
                            </div>
                        </a>

                    </div>
                </div>
                
            ))}

            {/* <div className="d-flex align-items-center p-3 w-100" style={{ width: '25rem'}}>
                <div>
                    <img className="card-img-top" src="https://via.placeholder.com/300.png/09f/fffC/O https://placeholder.com/" alt="Card image cap"/>
                </div>
                <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
            </div> */}
        </div>
    )
}

export default RunnerBody;
