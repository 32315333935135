import React from 'react';

function ActorJumbotron() {
    return (
            <div className="jumbotron jumbotron-fluid mb-0">
                <div className="container">
                    <h1 className="display-4">This is my Acting Page</h1>
                    <p className="lead">Maybe this paragraph can say what acting means for you. Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus tempore nam doloribus suscipit fugit quas. Fugiat sapiente corporis quasi dolore.</p>
                </div>
            </div>
    )
}

export default ActorJumbotron;