import React from 'react';

import FadeIn from 'react-fade-in';

import picture_1 from '../images/About The People/AboutThePeoplePoster.png';
import picture_2 from '../images/About The People/Coffey_Dorian_Diggy_Cropped.png';
import picture_3 from '../images/Runner/GQ_Floating_Card1.jpg';



function HomeBody() {
    return (
        <div>
            <FadeIn className=''>
                <div className="py-2 px-3">
                    <div className=" d-flex flex-column align-items-center justify-content-center flex-lg-row bg-color-2 py-3 px-3 rounded ">
                        <div className='d-flex'>
                            <div className='mb-2' >
                                <img src={picture_1} alt="1" className='rounded' style={{height: '300px'}} />
                            </div>
                            <div className="d-none d-sm-none d-md-none d-lg-block col-lg-3">
                                <img src={picture_2} alt="2" className='rounded' style={{ height: '300px' }} />
                            </div>
                            
                        </div>
                        <div className="text-center d-flex flex-column align-items-center col-lg-4 bg-color-3 rounded" >
                            <p className="text-responsive">About The People</p>
                            <p className=''>Concerned pillars in the African American community meet with one goal in mind; change. They grapple with the political, financial, and educational power structures in America, how they fit inside them, and plans for their re-engineering. Their open dialogue looks to find answers to tough social issues with the resolutions and ideas arriving through moments of volatile exchanges. The influence is not outside of us. It is us. Check out the short film <a className='textHoverEffect text-color-8' style={{textDecoration: 'none'}} href="https://aboutthepeoplefilm.com/" target='_blank'> <strong>here</strong></a>.</p>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-3">
                    <div className="d-flex flex-column-reverse align-items-center justify-content-center flex-lg-row bg-color-2 py-3 px-3 rounded">
                        <div className="text-center d-flex flex-column align-items-center col-lg-5 bg-color-3 rounded" >
                            <p className="text-responsive">Running To Protest</p>
                            <p>A group of runners in New York City who have come together to use our shared love of running to protest social injustice and advocate for the changes we want to see in the world. We hold monthly running events to create awareness around specific issues, something we started doing in the aftermath of the killings of Breonna Taylor, George Floyd, and Ahmaud Arbery. There’s many ways to protest, ours is to run.</p>
                        </div>
                        <div className="d-flex justify-content-center " style={{ height: '300px'}}>
                            <img src={picture_3} alt="1" className='rounded mx-2 my-auto' style={{height: '250px'}} />
                        </div>
                        
                    </div>
                </div>
            </FadeIn>
            {/* <div className="py-5 px-3 bg-gradient-secondary">
                <div className="row">
                    <div className="col-5">
                    <img src='https://source.unsplash.com/random/500x400' alt="1" />
                    </div>
                    <div className="col-6 d-flex flex-column align-items-center">
                        <p className="display-4">Here's Somthing</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime temporibus, eaque culpa soluta asperiores ipsum quibusdam expedita. Quam vel tempore mollitia assumenda iusto recusandae delectus doloremque sequi iste fuga earum dolor, culpa omnis blanditiis quisquam consequuntur libero est, sed numquam placeat expedita. Tempore quis quod voluptas, nihil id impedit corrupti?</p>
                    </div>
                </div>
            </div> */}
        </div>

    )
}

export default HomeBody;