import React, {useState} from 'react';

import FadeIn from 'react-fade-in';
import './newsBody.css';

import pic1 from '../images/News/Brooklyn_Reader.png';
import pic2 from '../images/News/Brooklyn_Running_Company.jpeg';
import pic3 from '../images/News/Darbey_Crew.jpeg';
import pic4 from '../images/News/FastCompany.jpg';
import pic5 from '../images/News/News12.png';
import pic6 from '../images/News/RunChats.png';
import pic7 from '../images/News/YouTube.png';
import pic8 from '../images/News/Brooklyn_Reader_2.jpg';
import pic9 from '../images/News/BehindTheWheel_2.png';
import pic10 from '../images/News/Deadline.png';
import pic11 from '../images/News/ThisIs50.png';
import pic12 from '../images/News/TheLoDown.png';
import pic13 from '../images/News/AboutThePeople.jpg';
import pic14 from '../images/News/RunnersWorld.png';
import pic15 from '../images/News/BlackFilm.png';
import pic16 from '../images/News/Outside.png';
import pic17 from '../images/News/RunnersOfNYC.png';
import pic18 from '../images/News/Vibe.png';
import pic19 from '../images/News/AMNY.png';
import pic20 from '../images/News/LuLuLemon.jpg';
import pic21 from '../images/News/NBC_2.png';

const NewsBody = () => {
    const [hover, setHover] = useState(false);

    const data = [
        {
            url: 'https://www.bkreader.com/2020/08/10/hundreds-of-runners-shut-down-brooklyn-bridge-to-honor-rep-john-lewis/',
            image: pic1,
            title: `Hundreds of Runners Shut Down Brooklyn Bridge to Honor Rep. John Lewis`,
            text: `The runners stopped traffic in heed of Lewis' famous words: "Get in good trouble, necessary trouble."`
        },
        {
            url: 'https://www.brooklynrunningco.com/community-spotlight-coffey/',
            image: pic2,
            title: `Community Spotlight: Coffey`,
            text: `One of the most powerful aspects of running is the way it brings people together and is such a binding force.`
        },
        {
            url: 'https://www.darbycommunications.com/blog-coffee-talk-with-filmmaker-and-runner-coffey/',
            image: pic3,
            title: `Coffee Talk with NYC Filmmaker and Runner, Coffey`,
            text: `We went to NYC for this month’s Coffee Talk to chat with About the People filmmaker, runner, and founder of Define New York Run Club, Coffey.`
        },
        {
            url: 'https://www.fastcompany.com/90534039/watch-michael-k-williams-debate-black-lives-matter-in-the-acclaimed-short-about-the-people',
            image: pic4,
            title: `Watch Michael K. Williams debate Black Lives Matter in the acclaimed short ‘About the People’`,
            text: `Coffey wrote About the People after his teenage son mentioned that he was afraid to be outside at night fearing that it could increase his chances of interacting with and being killed by police.`
        },
        {
            url: 'https://bronx.news12.com/2-brooklyn-filmmakers-pen-short-film-about-the-people-addressing-racial-injustice-42426489',
            image: pic5,
            title: `2 Brooklyn filmmakers pen short film ‘About the People,’ addressing racial injustice`,
            text: `Two local filmmakers in Brooklyn joined forces to create a short film examining social injustice and racial inequality.`
        },
        {
            url: 'https://bronx.news12.com/2-brooklyn-filmmakers-pen-short-film-about-the-people-addressing-racial-injustice-42426489',
            image: pic6,
            title: `RunningToProtest: Coffey Finds His Purpose | RunChats Ep.1‪6‬`,
            text: `I'm PROUD to sit down this week for a deep dive discussion with Coffey, aka @thatcoffeyboy.`
        },
        {
            url: 'https://www.youtube.com/watch?v=hrHC4URtgJE',
            image: pic7,
            title: `Running While Black | Human Race | Runner's World`,
            text: `"A Black man running, especially here in New York, we're already a threat. Outside your doors, I mean even inside now, you're a target and a threat. But outside I'm more of a target and a threat. And while running Black, man, it's a scary thing." -Coffey`
        },
        {
            url: 'https://www.bkreader.com/2020/07/23/video-brooklyn-writers-and-actors-lead-a-conversation-on-race-in-award-winning-short-film/',
            image: pic8,
            title: `Video: Brooklyn Writers and Actors Lead a Conversation on Race in Award-Winning Short Film`,
            text: `In 'About the People,' influential Black community members ask questions, propose solutions to racial inequality in the U.S.`
        },
        {
            url: 'https://anchor.fm/derek-oxley/episodes/That-Coffey-Boy-Interview-eglrfo/a-a2mgqvu',
            image: pic9,
            title: `Behind The Wheel Podcast`,
            text: `On June 3, I received a link to a movie, "About The People", it was from Angie a friend, from a former life.  In the movie were some familiar faces, I don't like to name drop, if you haven't already checked out the movie need to stop playing and go watch "About The People."`
        },
        {
            url: 'https://deadline.com/2020/07/about-the-people-short-film-series-michael-k-williams-coffey-steven-garcia-jeff-kalligheri-1202983591/',
            image: pic10,
            title: `WATCH: 2019 Fest Fave Short ‘About The People’ Optioned For Series: Prescient Take On Race Relations`,
            text: `After the death of George Floyd and the protest aftermath, some films that came out in recent years take on added resonance, particularly those that involve the murder of black youths at the hands of quick triggered and fearful cops.`
        },
        {
            url: 'https://thisis50.com/2020/06/28/interview-writer-actor-coffey-about-the-people-film/',
            image: pic11,
            title: `Interview: Writer/Actor Coffey- About The People Film`,
            text: `About The People is a short film I recently reviewed that delves deep into the topic of the systemic oppression of Black and Brown humans in America today.`
        },
        {
            url: 'http://www.thelodownny.com/leslog/2020/06/speaking-out-for-racial-justice-on-the-lower-east-side.html',
            image: pic12,
            title: `Speaking Out for Racial Justice on the Lower East Side`,
            text: `“Running to Protest” attracted hundreds on June 14th for a 2-mile, casual run (with Covid-19 masks and social distancing), followed by an on-stage rally.`
        },
        {
            url: 'https://thisis50.com/2020/06/23/about-the-people-film-review/',
            image: pic13,
            title: `About The People Film Review`,
            text: `There’s something to be said about a film about a specific moment in time that feels evergreen. I give this film 8.5 SALUTES out of 10.`
        },
        {
            url: 'https://www.runnersworld.com/runners-stories/a32880804/coffey-run-for-protest/',
            image: pic14,
            title: `Filmmaker Coffey Is Using His Voice to Influence the NYC Running Community`,
            text: `“Running to Protest” drew a crowd of about 700 runners, with a frank and open discussion about racism bringing various crews together.`
        },
        {
            url: 'https://www.blackfilm.com/read/2020/06/watch-michael-k-williams-dorian-missick-diggy-simmons-coffey-in-about-the-people/',
            image: pic15,
            title: `Watch Michael K. Williams, Dorian Missick, Diggy Simmons & Coffey In ‘About The People’`,
            text: `Currently playing online is About The People, a short film about the social injustices that black men/women go through.`
        },
        {
            url: 'https://www.outsideonline.com/2414587/coffey-running-new-york-city-racism',
            image: pic16,
            title: `Running While Black in New York`,
            text: `There’s been a running boom in the age of coronavirus, with veteran runners and newbies alike lacing up their shoes to get outside. But the experience has not been the same for everyone.`
        },
        {
            url: 'http://citiusmag.com/podcast/runners-of-nyc-podcast-coffey/',
            image: pic17,
            title: `Coffey, Define New York Run Club + On The Death of Ahmaud Arbery`,
            text: `"The footage of Ahmaud says it all. You see what happened. The guys are still out and about doing whatever it is that they do because this guy used to be a cop…Because he’s a white man, he gets away with it."`
        },
        {
            url: 'https://www.vibe.com/features/editorial/coffey-about-the-people-interview-669840/',
            image: pic18,
            title: `Filmmaker Coffey’s ‘About The People’ Depicts Honest Conversation On Being Black In America`,
            text: `About The People is inspired by a conversation Coffey had with his eldest son on police brutality.`
        },
        {
            url: 'https://www.amny.com/new-york/manhattan/neighborhoods/lower-manhattan/running-in-protest-activists-race-for-racial-justice-and-gun-violence-awareness-for-black-history-month/',
            image: pic19,
            title: `Running in protest: Activists race in Washington Square Park for racial justice and gun violence awareness during Black History Month`,
            text: `“We have been going through this for 400 and something years, so regardless of what happened to Malcolm X and what happened to Mr. Arbery in 2020, as I speak today in 2021 it is still the exact same thing that is going against our people and that is something we have to stop,” Coffey said.`
        },
        {
            url: 'https://www.runnersworld.com/gear/a36050682/lululemon-launches-global-run-line/',
            image: pic20,
            title: `Lululemon Launches Global Run Line`,
            text: `Lululemon has paired with running for change icons Mirna Valerio and Coffey, to launch its Global Run line, which is all about feeling fast and supported.`
        },
        {
            url: 'https://www.nbcphiladelphia.com/news/national-international/black-and-asian-americans-stand-together-against-hate-crimes/2780762/',
            image: pic21,
            title: `Black and Asian Americans Stand Together Against Hate Crimes`,
            text: `Asian and Black activists are talking solidarity, but people first need to know their common history and acknowledge differences and tensions that have long existed`
        },
        
        
    ]

    const image = {
        width: '14rem',
        height: '8rem',
    }
    return (
        <div >
            <FadeIn className='container d-flex flex-wrap justify-content-center my-5 bg-color-2 rounded'>
                {data.map((item, index) => (
                    <div key={index} className="mainCard card border-0 m-4 p-2 bg-color-3 " style={{ width: '16rem', height: '95%'}} >
                    <div className='rounded runnerCardHoverEffect-Outer p-1 h-100'>
                        <a href={item.url} target='_blank' className="d-flex flex-column justify-content-center" style={{textDecoration: 'none'}} >
                            <div style={{margin: '0 auto'}}>
                                <img className="card-img-top " src={item.image} style={image} alt="Coffey running for change"/>
                            </div>
                            <hr style={{marginRight: '20px', marginLeft: '20px' }}/>
                            <div className="card-body d-flex flex-column justify-content-center">
                                <h5 className="card-title text-center" style={{color: 'black'}}>{item.title}</h5>
                                <p className="card-text text-muted text-center">{item.text}</p>
                            </div>
                        </a>
                    </div>
                </div> 
                )) }
            </FadeIn>
        </div>
    )
}

export default NewsBody;
