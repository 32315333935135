import React, { useState, useCallback }  from 'react';
import Gallery from "react-photo-gallery";
import FadeIn from 'react-fade-in';
// import Carousel, { Modal, ModalGateway } from "react-images";
// import {photos} from './ActorPhotos';
import Lightbox from 'react-image-lightbox';

const photos = [
    {
        src: '/1final.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2final.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2019.6.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/7final.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2019.1.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2019.5.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2019.3.jpg',
        width: 3,
        height: 4
    },
    {
        src: '/2019.4.jpg',
        width: 3,
        height: 4
    },
];


function ActorBody() {
    // const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState();

    const openLightbox = (event, { photo, index }) => {
        setPhotoIndex(index);
        setViewerIsOpen(true);
        // console.log(index)
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
        setPhotoIndex(0);
    };

    // console.log(photos)

    
    return (
        <div >
            <FadeIn className='border border-color-8'>
                <Gallery photos={photos} onClick={openLightbox} className='border border-color-8' />

            </FadeIn>
                {/* {viewerIsOpen ? (
                <Lightbox
                    mainSrc={photos[photoIndex]}
                    nextSrc={photos[(photoIndex + 1) % photos.length]}
                    prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() =>
                        setPhotoIndex(photoIndex + photos.length - 1) % photos.length
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(photoIndex + 1) % photos.length
                    }
                />
                ) : null} */}
        </div>
        
    )
}

export default ActorBody;


