import React from 'react'

const ContactMain = () => {

    const imageStyle = {
        height: '100px',
        width: '100px', 
        borderRadius: '50%',
        margin: '10px'
    }

    return (
        <div className=' m-2 m-md-5 py-2 d-flex flex-column justify-content-center align-items-center text-center rounded bg-color-2' style={{height: '80vh'}}>

                <h5 className='mx-2'>
                    If you would like to contact Coffey and book him for a shoot, please click his pic  
                    <a href="mailto:coffeyhause@gmail.com">
                        <img src={require('../images/Contact/CoffeyScreaming.jpeg')} alt="The running to protest icon" className='footerIconHoverEffect' style={imageStyle} />
                    </a>
                </h5>


            <hr className='w-50'/>
            <h5 className='mx-2'>
                If you would like to join his running to protest movement, please click the fist <a href="https://www.instagram.com/runningtoprotest/" target="_blank"> <img src={require('../images/Contact/RunningToProtest.jpeg')} alt="The running to protest icon" className='footerIconHoverEffect' style={imageStyle} /></a>
            </h5>

            <hr className='w-50' />
            <h5 className='mx-2'>
                If you would like to join his Define New York running club or find out about the next run, click the icon <a href="https://www.instagram.com/definenewyorkrunclub/"> <img src={require('../images/Contact/DefineNewYork.png')} alt="The define new york run club logo" className='footerIconHoverEffect' style={imageStyle} /></a>
            </h5>
        </div>
    )
}

export default ContactMain;
