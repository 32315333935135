import React from 'react';
import { Link } from 'react-router-dom';
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { AiFillMail, AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { MdPhoneIphone } from 'react-icons/md';
import { FaFacebook } from 'react-icons/fa';
import { SiTiktok, SiImdb } from 'react-icons/si';

function Footer() {
    return (
        <footer className="page-footer font-small lighten-3 pt-4 bg-color-8">
            <div className="container text-center text-md-left">
                <div className="row">
                    <div className="col-md-2 col-lg-2 mx-auto my-md-4 my-0 mt-4 mb-1">
                        <h5 className="font-weight-bold text-uppercase mb-4 text-white">About</h5>
                            <ul className="list-unstyled">
                                <li>
                                    <Link to='/actor' className='text-dark' style={{textDecoration: 'none'}}><p className='textHoverEffect'>ACTOR</p></Link>
                                </li>
                                <li>
                                    <Link to='/storyteller' className='text-dark' style={{textDecoration: 'none'}}><p className='textHoverEffect'>STORYTELLER</p></Link>
                                </li>
                                <li>
                                   <Link to='/runner' className='text-dark' style={{textDecoration: 'none'}}><p className='textHoverEffect'>RUNNER</p></Link>
                                </li>
                                <li>
                                    <Link to='/end-racism' className='text-dark' style={{textDecoration: 'none'}}><p className='textHoverEffect'>END RACISM</p></Link>
                                </li>
                            </ul>

                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        <div className="col-md-4 col-lg-3 mx-auto my-md-4 my-0 mt-4 mb-1">
                        <h5 className="font-weight-bold text-uppercase mb-4 text-white">Address</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <p className="text-dark">
                                    <i className="mr-3 text-dark"><BsFillHouseDoorFill /> </i> Brooklyn, NY</p>
                                    </li>
                                    <li>
                                        <p className="text-dark textHoverEffect">
                                    <i className="mr-3 text-dark"><AiFillMail /></i> coffeyhause@gmail.com</p>
                                    </li>
                                    {/* <li>
                                        <p className="text-muted">
                                    <i className=" mr-3"><MdPhoneIphone /></i> + 01 234 567 88</p>
                                    </li> */}
                                    {/* <li>
                                        <p className="text-muted">
                                        <i className="mr-3"></i> + 01 234 567 89</p>
                                    </li> */}
                                </ul>
                            </div>
                            <hr className="clearfix w-100 d-md-none" />
                            <div className="col-md-2 col-lg-2 text-center mx-auto my-4 d-flex flex-column">
                                <h5 className="font-weight-bold text-uppercase mb-4 text-white">Follow Me</h5>
                                <a type="button" href="https://www.facebook.com/kelvin.coffey.3" className="btn-floating btn-fb footerIconHoverEffect" target='_blank'>
                                    <i className="h2 text-primary"><FaFacebook /></i>
                                </a>
                                <a type="button" href="https://twitter.com/thatcoffeyboy" className="btn-floating btn-tw footerIconHoverEffect" target='_blank'>
                                    <i className="h2 text-warning"><AiFillTwitterCircle /></i>
                                </a>
                                <a type="button" href="https://www.instagram.com/thatcoffeyboy/" className="btn-floating btn-gplus footerIconHoverEffect" target='_blank'>
                                    <i className="h2 text-info"><AiFillInstagram /></i>
                                </a>
                                <a type="button" href="https://www.imdb.com/name/nm2731528/?ref_=fn_nm_nm_1" className="btn-floating btn-dribbble footerIconHoverEffect" target='_blank'>
                                    <i className="h2 text-success"><SiImdb /></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <div className="footer-copyright text-center py-3 bg-muted">© 2020 Copyright:
                            <a href="https://mdbootstrap.com/"> MDBootstrap.com</a>
                    </div> */}

        </footer>
    )
}

export default Footer;