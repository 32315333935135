import React from 'react';
import ReactPlayer from 'react-player';

function EndRacismVideo() {
    return (
        <div className=''>
            <div className="" >
                <div className='embed-responsive embed-responsive-16by9'>
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=hrHC4URtgJE'
                        width='100%'
                        height='100%'
                    />

                </div>
            </div>
            <section class="jumbotron-fluid bg-light bg-color-1">
                <div class="container col-md-8 py-2 my-3 rounded bg-color-2">
                    <blockquote className='lead'>"Held six months to the day after the 26-year-old EMT was killed in a police raid on her apartment in Louisville, Kentucky, the run for Breonna was the fourth Running to Protest event that Coffey—a Brooklyn-based filmmaker, writer, actor, and activist who goes by his last name only—had organized since the murder of George Floyd on May 26th. Coffey told me he was motivated as much by anger over racial injustice as by a belief in the immense power of running to create a sense of community and effect change."</blockquote>
                    <footer className="blckquote-footer">- By David Alm <cite title="GQ">GQ</cite> </footer>
                </div>
            </section>

        </div>
    )
}

export default EndRacismVideo;