import React from 'react'

import NewsBody from './NewsBody';

const NewsMain = () => {
    return (
        <div>
            <NewsBody/>
        </div>
    )
}

export default NewsMain;
