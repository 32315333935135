import React from 'react';
import '../css/carousel.css';

import picture_1 from '../images/LandingPage/4final.jpg'
import picture_2 from '../images/LandingPage/6finalNL.jpg'
import picture_3 from '../images/LandingPage/2019.2.jpg'
import picture_4 from '../images/LandingPage/ATPactors.jpeg'
import picture_5 from '../images/LandingPage/hXdoJ9_A.jpeg'
import picture_6 from '../images/LandingPage/IMG_0028.JPG'
import picture_7 from '../images/LandingPage/IMG_7425.jpeg'


function Carousel() {
    return (
        
        <div id="carouselControls" className="carousel slide" data-ride="carousel" data-interval='3000' >
            <div className="carousel-inner">
                <div className="carousel-item bg-color-3 active" style={{ backgroundColor: ''}}>
                        <img className="d-block w-100" src={picture_1} style={{objectFit: 'contain'}} alt="First slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_7} style={{ objectFit: 'contain' }} alt="Seventh slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_2} style={{ objectFit: 'contain' }} alt="Second slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_5} style={{ objectFit: 'contain' }} alt="Fifth slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_3} style={{ objectFit: 'contain' }} alt="Third slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_4} style={{ objectFit: 'contain' }} alt="Fourth slide"/>
                </div>
                <div className="carousel-item bg-color-3" style={{ backgroundColor: '' }}>
                    <img className="d-block w-100" src={picture_6} style={{ objectFit: 'contain' }} alt="Sixth slide"/>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    )
}

export default Carousel;