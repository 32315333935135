import React from 'react';
import { Link , Router } from 'react-router-dom';


function Navbar() {
    return(
            <nav className="navbar navbar-expand-lg navbar-dark bg-color-8">
                    <a className="navbar-brand" href="/"><h3>The Coffey Exp.</h3> </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item ">
                            <Link to="/" className="nav-link navLinks textHoverEffect" href="/#">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item ">
                            <Link to='/actor' className="nav-link navLinks textHoverEffect" href="/#">
                                Actor
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/storyteller' className="nav-link navLinks textHoverEffect" href="/#">Storyteller</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/bio' className="nav-link navLinks textHoverEffect" href="/#">Biography</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/runner' className="nav-link navLinks textHoverEffect" href="/#">Runner</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/news' className="nav-link navLinks textHoverEffect" href="/#">News</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/contact' className="nav-link navLinks textHoverEffect" href="/#">Contact</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/end-racism' className="nav-link navLinks textHoverEffect" href="/#">End Racism</Link>
                        </li>
                    </ul>
                </div>
            </nav>
    )
}

export default Navbar;