import React from 'react';
import BioBody from './BioBody';

const BioMain = () => {
    return (
        <div className='d-flex flex-row justify-content-center align-items-center my-2' style={{minHeight: '90vh'}} >
            <BioBody/>
        </div>
    )
}

export default BioMain;
