/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import ReactPlayer from 'react-player';

import EndRacismVideo from './EndRacismVideo';
import EndRacismVideo_2 from '../images/End Racism/IMG_3926.mp4'

import { Parallax, Background } from 'react-parallax';
// import { Parallax } from 'react-scroll-parallax';


function EndRacismMain() {
    return (
        <div>
            <EndRacismVideo />

            <Parallax strength={2000} bgImage={require('../images/Runner/DSC09693.jpg')} className='d-none d-md-block' style={{ objectFit: 'contain', paddingTop: '250px' }}  >

                {/* <Background className="custom-bg d-sm-none'" >
                    <img src={require('../images/Runner/img_7425.jpeg')} alt="a picture of runners" style={{ objectFit: 'contain', marginTop: '100px' }} />
                </Background> */}

                {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
                    <img src='../images/Runner/img_7425.jpeg' /> */}



                <div style={{ height: '100px' }}></div>
                <div className="row" >
                    <div className="col-1"></div>
                        <div className="card bg-color-4 border-0 endRacismCardHoverEffect" style={{ width: '30%' }}>
                            <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.gq.com/story/the-inspiring-endurance-of-coffey-the-leader-of-new-york-citys-protest-runs'>
                                <img className="card-img-top" src={require('../images/Runner/GQ_Floating_Card1.jpg')} alt="Card image cap" />
                                <div className="card-body">
                                <p className="card-text" style={{ color: 'black' }}>The Inspiring Endurance of Coffey, The Leader of New York City’s Protest Runs.</p>
                                </div>
                            </a>
                        </div>

                </div>
                <div className="row" style={{ marginTop: '-100px' }}>
                    <div className="col-8"></div>
                        <div className="card bg-color-4 border-0 endRacismCardHoverEffect" style={{ width: '20%' }}>
                            <a style={{ textDecoration: 'none' }} target='_blank' href='https://podcasts.apple.com/ie/podcast/running-while-black-in-new-york/id1090500561?i=1000477467233'>
                                <img className="card-img-top" src={require('../images/Runner/Coffey_Running_While_BLK.png')} alt="Card image cap" />
                                <div className="card-body">
                                <p className="card-text" style={{ color: 'black' }}>“Running While Black in New York.” - Outside Podcast</p>
                                </div>
                            </a>
                    </div>
                </div>

                <div className="row" style={{ marginTop: '-150px' }}>
                    <div className="col-2"></div>
                        <div className="card bg-color-4 border-0 endRacismCardHoverEffect" style={{ width: '40%' }}>
                        <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.bkreader.com/2020/08/10/hundreds-of-runners-shut-down-brooklyn-bridge-to-honor-rep-john-lewis/'>
                                <img className="card-img-top" src={require('../images/Runner/DSC09764.jpg')} alt="Card image cap" />
                                <div className="card-body">
                                <p className="card-text" style={{ color: 'black' }}> “Hundreds of Runners Shut Down Brooklyn Bridge to Honor Rep John Lewis.” - Brooklyn Reader</p>
                                </div>
                            </a>
                        </div>
                    </div>
                <div style={{ height: '100px' }}></div>

                {/* </Parallax> */}
            </Parallax>

            <div className="row d-md-none" >
                <div className="card border-0 bg-color-1 p-4" >
                    <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.gq.com/story/the-inspiring-endurance-of-coffey-the-leader-of-new-york-citys-protest-runs'>
                        <img className="card-img-top rounded" src={require('../images/Runner/GQ_Floating_Card1.jpg')} alt="Card image cap" />
                        <div className="card-body">
                            <p className="card-text" style={{ color: 'black' }}>The Inspiring Endurance of Coffey, The Leader of New York City’s Protest Runs.</p>
                        </div>
                    </a>
                </div>

            </div>
            <div className="row d-md-none" >
                <div className="card border-0 bg-color-1 p-4"  >
                    <a style={{ textDecoration: 'none' }} target='_blank' href='https://podcasts.apple.com/ie/podcast/running-while-black-in-new-york/id1090500561?i=1000477467233'>
                        <img className="card-img-top rounded" src={require('../images/Runner/Coffey_Running_While_BLK.png')} alt="Card image cap" />
                        <div className="card-body">
                            <p className="card-text" style={{ color: 'black' }}>“Running While Black in New York.” - Outside Podcast</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className="row d-md-none">
                <div className="card border-0 bg-color-1 px-4" >
                    <a style={{ textDecoration: 'none' }} target='_blank' href='https://www.bkreader.com/2020/08/10/hundreds-of-runners-shut-down-brooklyn-bridge-to-honor-rep-john-lewis/'>
                        <img className="card-img-top rounded" src={require('../images/Runner/DSC09764.jpg')} alt="Card image cap" />
                        <div className="card-body">
                            <p className="card-text" style={{ color: 'black' }}> “Hundreds of Runners Shut Down Brooklyn Bridge to Honor Rep John Lewis.” - Brooklyn Reader</p>
                        </div>
                    </a>
                </div>
            </div>

            <div className="embed-responsive embed-responsive-4by3">
                <ReactPlayer
                    className='react-player'
                    url={EndRacismVideo_2}
                    width='100%'
                    height='100%'
                    controls={true}

                />
            </div>

        </div>
    )
}

export default EndRacismMain;