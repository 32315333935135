import React from 'react';

import ActorBody from './ActorBody';
import ActorJumbotron from './ActorJumbotron';
import ActorParallax from './ActorParallax';


function ActorMain() {
    return (
        <div>
            {/* <ActorJumbotron /> */}
            <ActorParallax />
            <ActorBody />
        </div>
    )

}

export default ActorMain;