import React from 'react';
import { Parallax, Background } from 'react-parallax';
import FadeIn from 'react-fade-in';


function ActorParallax() {
    return(
        <div className='d-none d-md-inline'>
            <FadeIn>
                <Parallax
                    blur={0}
                    bgImage={require('../images/Actor/2019.2.jpg')}
                    bgImageAlt="Headshot"
                    bgImageStyle={{
                        paddingTop: '250px'
                    }}
                    strength={800}
                >
                    <div style={{ height: '750px' }}>
                    </div>
                </Parallax>
                <div className="container my-1"></div>
                <Parallax
                    blur={{ min: 0, max: 0 }}
                    bgImage={require('../images/Actor/3final.jpg')}
                    bgImageAlt="Headshot"
                    bgImageStyle={{
                        paddingTop: '200px'
                    }}
                    strength={850}
                >
                    <div style={{ height: '750px' }} className="d-flex justify-content-center align-items-center">
                    </div>
                </Parallax>

            </FadeIn>

            {/* <Parallax
                blur={0}
                bgImage={require('../images/Actor/4final.jpg')}
                bgImageAlt="the dog"
                strength={600}
            >

                <div style={{ height: '600px' }} ></div>
            </Parallax> */}
            {/* <div className="container">
                <p className="display-3">Here's Another One</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, tenetur provident doloremque porro consectetur nesciunt iure! Reiciendis qui quasi error fugiat at maiores earum fuga, cupiditate modi asperiores, est iure?</p>
            </div> */}
            {/* <Parallax strength={300}>
                <div>Use the background component for custom elements</div>
                <Background className="custom-bg">
                    <img src="http://www.fillmurray.com/500/320" alt="fill murray" />
                </Background>
            </Parallax> */}
        </div>
    )
}

export default ActorParallax;