/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import RunnerVideo from './RunnerVideo';
import RunnerBody from './RunnerBody';


function RunnerMain() {
    return(
        <div>
            <RunnerVideo/>
            <RunnerBody/>
        </div>
    )
}

export default RunnerMain;