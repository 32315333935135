import React from 'react';
import FadeIn from 'react-fade-in';


const pill = {
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'lightgrey',
    width: '90%'
}

const StorytellerMain = () => {

const data = [
    {
        name: 'About The People'
    },
    {
        name: 'About The People limited series'
    },    
    {
        name: 'The Iron Horsemen'
    },
    {
        name: 'Dear Summer'
    },
    {
        name: 'Saying So Long'
    },
    {
        name: 'Print'
    },
    {
        name: 'Boroughs'
    },
    {
        name: 'New York Was'
    },
    {
        name: 'Back'
    },
    {
        name: 'A Love Cry Out'
    },
    {
        name: 'A Janitor'
    },
    {
        name: 'KCJ'
    },
]

    return (
        <div className='container my-5 rounded d-flex flex-column align-items-center bg-color-2 cardContainer' >
            <div className='card border-0 m-1 m-4 p-md-5 bg-color-3 d-flex flex-column align-items-center justify-contents-center cardWidth' >

                {/* {data.map((element, index) => (
                    <div>
                        <h4 className='py-3 m-3 bg-color-3' style={pill} >{element.name}</h4>
                    </div>
                ))} */}
                    
                    <h4 className='py-3 m-3 bg-color-5 storytellerHoverEffect text-dark' style={pill} >
                        <a href="https://aboutthepeoplefilm.com/" target="_blank" rel="noopener noreferrer" className='text-dark' style={{textDecoration: 'none'}}>
                            About The People
                        </a>
                    </h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >About The People limited series</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >The Iron Horsemen</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >Dear Summer</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >Saying So Long</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >Print</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >Boroughs</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >New York Was</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >Back</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >A Love Cry Out</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >A Janitor</h4>
                    <h4 className='py-3 m-3 bg-color-5' style={pill} >KCJ</h4>

            </div>
        </div>
    )
}
export default StorytellerMain;
