import React from 'react';

import FadeIn from 'react-fade-in';

const BioBody = () => {
    return (
        <div className='container m-2 p-3 m-lg-5 p-lg-5 bg-color-2 rounded' >
            <FadeIn>
                <p>
                    <span class="firstcharacter">O</span>riginally from the small town of Aurora, North Carolina, Coffey came to New York City after receiving a Bachelor’s degree in Speech & Drama from Virginia Union University to chase the dream of acting. Between auditions, he picked up modeling gigs, got into fashion, and eventually became an editor at XXL Magazine, all the while moonlighting as a screenwriter.
                </p>
                <p>
                    <span class="firstcharacter">C</span>offey started running for his physical health, but he loved the escape and clarity it gave his busy mind. At first, he ran mostly at night, often alone, but as time passed, he found himself leading whole groups of runners, especially those who felt out of place in the mostly-white spaces of running clubs. Among the NYC running community, he’s earned a reputation for his encyclopedic knowledge of the city, and in early 2020 he founded Define New York Run Club, known for their city-wide explorations and emphasis on inclusivity—"I run. You run. We run together. No one gets left behind.”
                </p>
                <p>
                    <span class="firstcharacter">U</span>ntil recently, Coffey held these two worlds--his work in media and his running life--separate. But in the aftermath of the murders of Breonna Taylor, George Floyd, and Ahmaud Arbery, Coffey put out an all-call to the NYC running community: come together and publicly denounce social injustice in all its forms. Over 1,500 runners answered the call, and Running to Protest was born.
                </p>
                <p>
                    <span class="firstcharacter">C</span>offey’s role as a leader among runners is now clear. Once a month, Coffey and the growing Running to Protest team organize a monthly protest run. Each one focuses on a different issue of injustice, and includes expert speakers and elements of education, advocacy, and calls to actions.
                </p>
                <p>
                    <span class="firstcharacter">I</span>t was only through Running to Protest that the larger running community learned about Coffey’s work in film. Most pertinently, about his 2019 award winning short film, “About the People,” which dramatizes a conversation among pillars of an African American Community coming together for change. Coffey’s been doing the work all along - he’s just been waiting for us to fall in stride.
                </p>
            </FadeIn>
        </div>
    )
}

export default BioBody;
