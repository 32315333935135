import React from "react";
import { Route, Switch } from "react-router-dom";

import HomeMain from './components/HomeMain.js';
import ActorMain from './components/ActorMain';
import RunnerMain from './components/RunnerMain';
import EndRacismMain from './components/EndRacismMain'
import StorytellerMain from "./components/StorytellerMain.js";
import NewsMain from './components/NewsMain';
import ContactMain from './components/ContactMain';
import BioMain from './components/BioMain';

function Routes() {
    return (
        <Switch>
            <Route path="/runner" component={RunnerMain} />
            <Route path="/storyteller" component={StorytellerMain} />
            <Route path="/actor" component={ActorMain} />
            <Route path='/end-racism' component={EndRacismMain} />
            <Route path='/news' component={NewsMain} />
            <Route path='/contact' component={ContactMain} />
            <Route path='/bio' component={BioMain} />
            <Route path="/" component={HomeMain} />
        </Switch>
    )
}

export default Routes;