import React from 'react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Routes from './Routes';


import './App.css';
import './scss/custom.css';
import 'react-image-lightbox/style.css';





function App() {
  return (
    <div className='bg-color-1'>
      <Navbar />
        <Routes />
      <Footer />
    </div>
  );
}

export default App;
