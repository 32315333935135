import React from 'react';
import ReactPlayer from 'react-player';

import Runner_Video from '../images/Runner/LuLuLemon.mp4'

function RunnerVideo() {
    return (
        <div className='embed-responsive embed-responsive-16by9'>
            <ReactPlayer 
                url={Runner_Video}
                width='100%'
                height='100%'
                controls={true}
                playing={true}
            />
        </div>
    )
}

export default RunnerVideo;